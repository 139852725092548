
import { defineComponent, ref } from "vue";
import api from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Register",
  setup() {
    const router = useRouter();
    const name = ref('');
    const email = ref('');
    const password = ref('');
    const confirm = ref('');

    async function register() {
      if(name.value && email.value && password.value) {
        if(password.value !== confirm.value) alert('Las contraseñas no coinciden');
        else {
          try {
            await api.post("auth/registration", {
              name: name.value,
              email: email.value,
              password: password.value,
            });
            await router.push('/');
          } catch (e) {
            alert(e.response.data.message);
          }
        }
      }
    }

    return { name, email, password, confirm, register }
  }
});
