
import { defineComponent } from "vue";

export default defineComponent({
  name: 'PageTitle',
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      type: String
    }
  }
})
