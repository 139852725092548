
import { defineComponent, ref } from "vue";
import api from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Recover",
  setup() {
    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const confirm = ref('');
    const resetCode = ref('');
    const sent = ref(false);

    async function sendResetCode() {
      if(email.value) {
        try {
          await api.post("auth/resetCode", {
            email: email.value,
          });
          sent.value = true;
        } catch (e) {
          alert(e.response.data.message);
        }
      }
    }

    async function reset() {
      if(email.value && password.value && resetCode.value) {
        if(password.value !== confirm.value) alert('Las contraseñas no coinciden');
        else {
          try {
            await api.post("auth/password", {
              email: email.value,
              password: password.value,
              resetCode: resetCode.value
            });
            await router.push('/');
          } catch (e) {
            alert(e.response.data.message);
          }
        }
      }
    }

    return { email, password, confirm, resetCode, sent, sendResetCode, reset }
  }
});
