
import { defineComponent, computed } from "vue";
import {useRoute} from 'vue-router';
import GroupView from "@/components/GroupView.vue";
import GroupEdit from "@/components/GroupEdit.vue";

export default defineComponent({
  name: 'AppLayout',
  components:{
    view: GroupView,
    edit: GroupEdit
  },
  setup ()
  {
    const route = useRoute();
    const mode = computed(() => (route.params.id === 'new') ? 'edit' : 'view')

    return {mode};
  }
});
