import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from "vue-router";
import About from "../views/About.vue";
import SignIn from "../views/SignIn.vue";
import Register from "../views/Register.vue";
import Profile from "../views/Profile.vue";
import Activities from "../views/Activities.vue";
import Categories from "../views/Categories.vue";
import Favorites from "../views/Favorites.vue";
import Activity from "../views/Activity.vue";
import Category from "@/views/Category.vue";
import Groups from "@/views/Groups.vue";
import Rewards from "@/views/Rewards.vue";
import store from "@/store";
import Recover from "@/views/Recover.vue";
import Group from "@/views/Group.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    meta: {
      layout: 'public'
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      layout: 'public'
    }
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
    meta: {
      layout: 'public'
    }
  },
  {
    path: "/",
    name: "Profile",
    component: Profile,
    meta: {
      layout: 'main',
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/activities",
    name: "Activities",
    component: Activities,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/activities/:id",
    name: "Activity",
    component: Activity,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/categories/:id",
    name: "Category",
    component: Category,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/groups/:id",
    name: "Group",
    component: Group,
    meta: {
      layout: 'main'
    }
  },
  {
    path: "/rewards",
    name: "Rewards",
    component: Rewards,
    meta: {
      layout: 'main'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async (to:RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) =>
{
  document.title = 'Real Hero';
  const me = await store.dispatch('getMe');
  if (to.meta.layout === 'main' && !me)
  {
    next({name: 'SignIn'});
  }
  else
  {
    next();
  }
});

export default router;
