import { createStore } from "vuex";
import api from "@/api";

export default createStore({
  state: {
    me: undefined as any | undefined,
    userId: '',
    favorites: [] as string[],
    groups: [] as string[],
    loading: false
  },
  mutations: {},
  actions: {
    async getFavorites({state}) {
      try {
        const result = await api.get('users/me/favorites');
        state.favorites = result.data.map((activity: any) => activity.id);
      } catch (e) {
        console.log(e);
      }
    },
    async getGroups({state}) {
      try {
        const result = await api.get('users/me/groups');
        state.groups = result.data.map((group: any) => group.id);
      } catch (e) {
        console.log(e);
      }
    },
    async toggleFavorite({state}, activityId: string) {
      try {
        const favorite = !state.favorites.some((id: string) => id === activityId);
        await api.post('users/me/favorites',{
          mission: {
            id: activityId
          },
          favorite
        });
        if (favorite) {
          state.favorites.push(activityId);
        } else {
          state.favorites = state.favorites.filter((id: string) => id !== activityId);
        }
      } catch (e) {
        console.log(e);
        alert('Error');
      }
    },
    async toggleMember({state}, groupId: string) {
      try {
        const isMember = !state.groups.some((id: string) => id === groupId);
        await api.post('users/me/groups',{
          group: {
            id: groupId
          },
          isMember
        });
        if (isMember) {
          state.groups.push(groupId);
        } else {
          state.groups = state.groups.filter((id: string) => id !== groupId);
        }
      } catch (e) {
        console.log(e);
        alert('Error');
      }
    },
    async getMe({state, dispatch}) {
      try{
        if(!state.me) {
          const result = await api.get('users/me');
          dispatch('getFavorites');
          dispatch('getGroups');
          state.me = result.data;
        }
        return state.me;
      }
      catch (e) {
        return state.me;
      }
    }
  },
  modules: {},
});
