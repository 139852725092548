import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
}).mount('#app');
