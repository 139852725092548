
import { defineComponent, ref } from "vue";
import PageTitle from "@/components/PageTitle.vue";

export default defineComponent ({
  name: "Rewards",
  components: {
    PageTitle
  },
  setup (){
    const rewards = ref([]);

    return { rewards }
  }
});
