
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent ({
  name: "GroupsList",
  props: {
    groups: {
      required: true,
      type: Array
    }
  },
  setup() {
    const store = useStore()

    function isMember(groupId: string) {
      return store.state.groups.some((id: string) => id === groupId);
    }

    function toggleMember(groupId: string) {
      store.dispatch('toggleMember', groupId);
    }

    function getPhotoUrl(id: string) {
      return `${process.env.VUE_APP_API_BASE_URL}groups/${id}/photo`
    }

    return { toggleMember, isMember, getPhotoUrl }
  }
});
