import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "content mt-n2 mb-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  style: {"height":"90px"},
  class: "mx-0 card card-style card-hide round-medium shadow-large"
}
const _hoisted_5 = { class: "card-center pointer" }
const _hoisted_6 = { class: "display-5 color-white mx-0 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_title, {
      title: 'Categorías',
      subtitle: 'Explora actividades por categoría'
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-6 col-md-4",
            key: index,
            onClick: ($event: any) => (_ctx.$router.push(`/categories/${category.id}`))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h1", _hoisted_6, _toDisplayString(category.name), 1)
              ]),
              _createElementVNode("div", {
                class: "card-overlay",
                style: _normalizeStyle({'background-color': category.color})
              }, null, 4)
            ])
          ], 8, _hoisted_3))
        }), 128))
      ])
    ])
  ], 64))
}