
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent ({
  name: "ActivitiesList",
  props: {
    activities: {
      required: true,
      type: Array
    }
  },
  setup() {
    const store = useStore();

    function formatLocations(locations: { city: string; country: string; }[]) {
      const reduced = locations.reduce((acc: { city: string; country: string; }[], location ) => {
        if(!acc.some(l => l.city === location.city && l.country === location.country)){
          acc.push(location);
        }
        return acc;
      }, []);
      if (reduced.length > 1) {
        return `${reduced.length} Ciudades`;
      } else if (reduced.length === 1) {
        return `${reduced[0].city}, ${reduced[0].country}`;
      } else {
        return 'Ubicación no determinada';
      }
    }

    function isFavorite(activityId: string) {
      return store.state.favorites.some((id: string) => id === activityId);
    }

    function toggleFavorite(activityId: string) {
      store.dispatch('toggleFavorite', activityId);
    }

    function getPhotoUrl(id: string) {
      return `${process.env.VUE_APP_API_BASE_URL}missions/${id}/photo`
    }

    return { formatLocations, isFavorite, toggleFavorite, getPhotoUrl }
  }
});
