
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { shortDate } from "@/tools/filters";
import { useStore } from "vuex";
import api from "@/api";
import { QrStream } from "vue3-qr-reader";
import { readImage } from "@/tools/images";
import { IActivity } from "@/models/IActivity";
import { ILocation } from "@/models/ILocation";
import axios from "axios";

export default defineComponent ({
  name: "Activity",
  components: {
    QrStream
  },
  setup(){
    const capture = ref(false);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const userId = computed(() => store.state.me.id);
    const activity = ref({} as IActivity);
    const history = ref([]);
    const registry = ref({
      code: '',
      remarks: '',
      photos: [] as string[]
    });
    const tab = ref(1);
    const isFavorite = computed(() => store.state.favorites.some((id: string) => id === activity.value.id));
    const showRegistryModal = ref(false);
    const photo = ref('');

    const map = ref({
      center: undefined,
      markers: [] as any[]
    });

    async function getActivity(){
      try {
        const id = route.params.id.toString();
        const response = await api.get(`missions/${id}`);
        activity.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function getHistory(){
      try {
        const id = route.params.id.toString();
        const response = await api.get(`missions/${id}/history?userId=${userId.value}`);
        history.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function register(){
      try {
        await api.post(
          'users/me/missions',
          {...registry.value, mission: {id: activity.value.id}}
        );
        showRegistryModal.value = false;
        //cambiar
        router.push('/');
      } catch (e) {
        console.log(e);
      }
    }

    function toggleFavorite() {
      store.dispatch('toggleFavorite', activity.value.id)
    }

    function onDecode(data: never) {
      registry.value.code = data;
      capture.value = false;
    }

    onMounted(async () =>
    {
      await getActivity();
      await getHistory();
      await getLatLng()
    });

    function getPhotoUrl() {
      const id = route.params.id.toString();
      return `${process.env.VUE_APP_API_BASE_URL}missions/${id}/photo`
    }

    // eslint-disable-next-line
    async function getImages(event: any) {
      try {
        for (let i=0; i<event.target.files.length; i++) {
          registry.value.photos.push(await readImage(event.target.files[i]));
        }
        console.log(registry.value);
      } catch (e) {
        console.log(e);
      }
    }

    function removePhoto(index: number) {
      registry.value.photos.splice(index, 1);
    }

    function canRegister() {
      let can = false;
      if(activity.value.group) {
        const groupId = activity.value.group.id;
        can = store.state.groups.some((id: string) => id === groupId);
      } else {
        can = true;
      }
      return can;
    }

    async function getLatLng() {
      if(activity.value.locations.length) {
        try {
          for (let i = 0; i <= activity.value.locations.length; i++)
          {
            const location = activity.value.locations[i];
            const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(location.address)},${encodeURIComponent(location.city)},${encodeURIComponent(location.country)}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
            const response = await axios.get(url);
            map.value.markers.push({
              position: response.data.results[0].geometry.location,
              address: `${location.address} - ${location.city}, ${location.country}`
            });
            if(i === 0) map.value.center = response.data.results[0].geometry.location;
          }
        } catch (e) {
          console.log('Could not geolocate');
        }
      }
    }

    return {
      tab,
      activity,
      history,
      photo,
      registry,
      isFavorite,
      showRegistryModal,
      capture,
      map,
      onDecode,
      register,
      shortDate,
      toggleFavorite,
      getPhotoUrl,
      getImages,
      removePhoto,
      canRegister
    }
  }
});
