
import { defineComponent, onMounted, ref } from "vue";
import { ILocation } from "@/models/ILocation";
import { IActivity } from "@/models/IActivity";
import api from "@/api";
import { useRouter } from "vue-router";
import { ICategory } from "@/models/ICategory";
import { readImage } from "@/tools/images";
import { IGroup } from "@/models/IGroup";

export default defineComponent({
  name: 'ActivityEdit',
  setup() {
    const router = useRouter();
    const activity = ref();
    const location = ref();
    const categories = ref([] as ICategory[])
    const groups = ref([] as IGroup[])
    const showLocationModal = ref(false);
    const points = ref(false);
    const impact = ref(false);
    const confirmation = ref(false);

    function init() {
      activity.value = {
        photo: undefined as string | undefined,
        name: '',
        description: '',
        points: 0,
        impact: 0,
        group: undefined,
        locations: [] as ILocation[]
      } as IActivity;
      location.value = {
        name: '',
        address: '',
        city: '',
        country: '',
        startDate: undefined,
        endDate: undefined
      } as ILocation;
      console.log(location.value);
    }


    function addLocation() {
      activity.value.locations.push(location.value);
      console.log(location.value);
      showLocationModal.value = false;
      location.value = {
        name: '',
        address: '',
        city: '',
        country: '',
        startDate: undefined,
        endDate: undefined
      };
    }

    function removeLocation(index: number) {
      activity.value.locations.splice(index, 1);
    }

    async function save() {
      try {
        const data = {...activity.value};
        data.points = points.value ? data.points : 0;
        data.impact = impact.value ? data.impact : 0;
        data.startDate = confirmation.value ? data.startDate : undefined;
        data.endDate = confirmation.value ? data.endDate : undefined;
        data.locations = confirmation.value ? data.locations : undefined;
        const result = await api.post('missions', data);
        await router.push(`/activities/${result.data.id}`);
      } catch(e) {
        console.log(e);
        alert("Error guardando la actividad");
      }
    }

    async function getCategories() {
      try {
        const response = await api.get('categories');
        categories.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function getGroups() {
      try {
        const response = await api.get('groups?groups=owned');
        groups.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function getImage(event: any) {
      try {
        activity.value.photo = await readImage(event.target.files[0]);
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () =>
    {
      init();
      await getCategories();
      await getGroups();
    });

    return { activity, location, categories, groups, showLocationModal, points, impact, confirmation, addLocation, removeLocation, save, getImage }
  }
});
