
import { defineComponent, onMounted, ref } from "vue";
import PageTitle from '@/components/PageTitle.vue';
import api from "@/api";

export default defineComponent ({
  name: "Categories",
  components: {
    PageTitle
  },
  setup() {
    const categories = ref([]);

    async function getCategories() {
      try {
        const response = await api.get('categories');
        categories.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () =>
    {
      await getCategories();
    });

    return { categories }
  }
});
