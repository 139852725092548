
import { defineComponent, onMounted, ref } from "vue";
import api from "@/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "SingIn",
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref('');
    const password = ref('');
    function social(provider: string) {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}auth/${provider}`;
    }

    async function signIn() {
      if(email.value && password.value) {
        try {
          await api.post("auth", {
            email: email.value,
            password: password.value
          });
          await router.push('/');
        } catch (e) {
          alert(e.response.data.message);
        }
      }
    }

    onMounted(async () => {
      await api.delete('auth');
      store.state.me = undefined;
    });

    return { email, password, signIn, social }
  }
});
