
import { computed, defineComponent, onMounted, ref } from "vue";
import ActivitiesList from "@/components/ActivitiesList.vue";
import PageTitle from "@/components/PageTitle.vue";
import { useStore } from "vuex";
import api from "@/api";

export default defineComponent ({
  name: "Favorites",
  components: { ActivitiesList, PageTitle },
  setup (){
    const store = useStore();
    const activities = ref([]);
    const favorites = computed(() => {
      return activities.value.filter((activity: {id: string}) => {
        return store.state.favorites.some((id: string) => id === activity.id);
      });
    });

    async function getFavorites() {
      try {
        const response = await api.get('users/me/favorites');
        activities.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () =>
    {
      await getFavorites();
    });

    return { favorites }
  }
});
