
import { computed, defineComponent, onMounted, ref, watchEffect } from "vue";
import PageTitle from '@/components/PageTitle.vue';
import ActivitiesList from '@/components/ActivitiesList.vue';
import Dropdown from '@/components/Dropdown.vue';
import api from "@/api";
import { ICategory } from "@/models/ICategory";
import { useStore } from "vuex";

export default defineComponent ({
  name: "Activities",
  components: {
    PageTitle,
    ActivitiesList,
    Dropdown
  },
  setup () {
    const store = useStore();
    const isAdmin = computed(() => {
      return store.state.me.roles.some((role: any) => role.name === 'admin');
    });
    const activity = ref({});
    const categories = ref([]);
    const activities = ref([]);
    const search = ref('');
    const filter = ref({
      categoryId: '',
      groups: ''
    });
    const options = computed(() => {
      const categoriesOptions: any[] = [{
        title: 'Todas las categorias',
        value: '',
        image: require('@/assets/img/corazon.svg')
      }];
      categories.value.forEach((category: ICategory) => {
        categoriesOptions.push({
          title: category.name,
          value: category.id,
          icon: category.icon,
          color: category.color
        });
      });

      return {
        groups: [{
          title: 'Todos los grupos',
          value: '',
          image: require('@/assets/img/manos-corazon.svg')
        },{
          title: 'Mis grupos',
          value: 'joined',
          image: require('@/assets/img/mano.svg')
        }],
        categories: categoriesOptions
      }
    });

    async function getActivities(search: string, filter: { categoryId: string, groups: string }) {
      search = search.trim();
      try {
        const response = await api.get('missions', {
          params: {...filter, search }
        });
        activities.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    let timeoutRef: number | null = null;
    function debounce(callback: () => void) {
      if (timeoutRef !== null) window.clearTimeout(timeoutRef);
      timeoutRef = window.setTimeout(async () =>
      {
        callback();
      }, 500);
    }

    watchEffect(async () => {
      await getActivities(search.value, filter.value);
    });

    async function getCategories() {
      try {
        const response = await api.get('categories');
        categories.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () =>
    {
      await getCategories();
    });

    return { isAdmin, search, options, filter, activities, activity, debounce }
  }
});
