
import { defineComponent, computed } from "vue";
import ActivityView from '@/components/ActivityView.vue';
import ActivityEdit from '@/components/ActivityEdit.vue';
import {useRoute} from 'vue-router';

export default defineComponent({
  name: 'AppLayout',
  components:{
    view: ActivityView,
    edit: ActivityEdit
  },
  setup ()
  {
    const route = useRoute();
    const mode = computed(() => (route.params.id === 'new') ? 'edit' : 'view')

    return {mode};
  }
});
