
import { defineComponent, onMounted, ref } from "vue";
import ActivitiesList from "@/components/ActivitiesList.vue";
import PageTitle from "@/components/PageTitle.vue";
import { useRoute } from "vue-router";
import api from "@/api";

export default defineComponent ({
  name: "Category",
  components: { ActivitiesList, PageTitle },
  setup (){
    const route = useRoute();
    const category = ref({});
    const activities = ref([]);

    async function getCategory() {
      try {
        const id = route.params.id.toString();
        const response = await api.get(`categories/${id}`);
        category.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function getActivities() {
      try {
        const id = route.params.id.toString();
        const response = await api.get(`missions?categoryId=${id}`);
        activities.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () =>
    {
      await getCategory();
      await getActivities();
    });

    return { category, activities }
  }
});
