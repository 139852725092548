import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content mt-n2 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!
  const _component_activities_list = _resolveComponent("activities-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_title, {
      title: _ctx.category.name,
      subtitle: _ctx.category.description
    }, null, 8, ["title", "subtitle"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_activities_list, { activities: _ctx.activities }, null, 8, ["activities"])
    ])
  ], 64))
}