
import {defineComponent, computed} from 'vue';
import Public from '@/layouts/Public.vue';
import Main from '@/layouts/Main.vue';
import {useRoute} from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
  name: 'AppLayout',
  components:{
    public: Public,
    main: Main
  },
  setup ()
  {
    const store = useStore();
    const loading = computed(() => store.state.loading);

    const route = useRoute();
    const layout =  computed(()=> route.meta.layout);

    return {layout, loading};
  }
});
