
import { defineComponent, ref, watchEffect } from "vue";
import GroupsList from "@/components/GroupsList.vue";
import PageTitle from "@/components/PageTitle.vue";
import Dropdown from "@/components/Dropdown.vue";
import api from "@/api";

export default defineComponent ({
  name: "Groups",
  components: {
    PageTitle,
    GroupsList,
    Dropdown
  },
  setup (){
    const groups = ref([]);
    const search = ref('');
    const filter = ref('');
    const options = [{
      title: 'Todos los grupos',
      value: '',
      image: require('@/assets/img/manos-corazon.svg')
    },{
      title: 'Mis grupos',
      value: 'joined',
      image: require('@/assets/img/mano.svg')
    }];

    async function getGroups(search: string, filter: string) {
      search = search.trim();
      try {
        const response = await api.get('groups', {
          params: {
            search,
            groups: filter
          }
        });
        groups.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    let timeoutRef: number | null = null;
    function debounce(callback: () => void) {
      if (timeoutRef !== null) window.clearTimeout(timeoutRef);
      timeoutRef = window.setTimeout(async () =>
      {
        callback();
      }, 500);
    }

    watchEffect(async () => {
      await getGroups(search.value, filter.value);
    });

    return { search, groups, options, filter, debounce }
  }
});
