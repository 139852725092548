import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true
});

api.interceptors.request.use(function (config) {
  store.state.loading = true;
  return config;
}, function (error) {
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  store.state.loading = false;
  return response;
}, function (error) {
  store.state.loading = false;
  return Promise.reject(error);
});

export default api;