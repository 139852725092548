<template>
  <ul class="dropdown-options mb-2" :class="{'active': active}" @click="active = !active">
    <li v-for="(option, index) in options" :key="index"
        :class="{'selected': selected === option.value}"
        @click="$emit('update:selected', option.value)">
      <div class="dropdown-options-icon">
        <img v-if="option.image" :src="option.image" alt="option">
        <span :style="{color: option.color || 'auto'}">
          <i v-if="option.icon" :class="option.icon"></i>
        </span>
      </div>
      <div class="dropdown-options-title">{{ option.title }}</div>
    </li>
  </ul>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: 'Dropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: String
    }
  },
  setup() {
    const active = ref(false);

    return { active };
  }
})
</script>

<style lang="scss" scoped>
.dropdown-options {
  background-color: white;
  overflow: hidden;
  margin: 0px 16px;
  border-radius: 15px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 8%);
  padding: 0;
  position: relative;

  li {
    display: none;
    flex-direction: row;
    align-items: center;
    margin: 1em auto;
    cursor: pointer;

    &.selected {
       display: flex;
     }

    .dropdown-options-icon {
      width: 4rem;
      text-align: center;
      img {
        width: 2rem;
        height: 2rem;
      }
      span {
        vertical-align: middle;
        font-size: 1.3rem;
      }
    }

    .dropdown-options-title {
      flex-grow: 1;
      font-size: 1.3rem;
      font-weight: 300;
      color: #46a5f9;
    }
  }

  &::after {
    display: block;
    position: absolute;
    top: 1rem;
    right: 0;
    width: 4rem;
    text-align: center;
    height: 26px;
    line-height: 26px;
    vertical-align: middle;
    font-size: 1.3rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f078";
    cursor: pointer;
    background-color: transparent;
  }

  &.active {
    li{
      display: flex !important;
    }

    &::after {
      content: "\f077" !important;
    }
  }
}
</style>