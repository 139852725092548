
import { defineComponent, onMounted, ref } from "vue";
import ActivitiesList from "@/components/ActivitiesList.vue";
import api from "@/api";
import { IGroup } from "@/models/IGroup";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { IUser } from "@/models/IUser";

export default defineComponent ({
  name: "Group",
  components: { ActivitiesList},
  setup () {
    const store = useStore();
    const route = useRoute();
    const id = route.params.id.toString();
    const group = ref({} as IGroup);
    const tab = ref(1);
    const activities = ref([]);
    const members = ref([] as IUser[]);

    async function getGroup() {
      try {
        const response = await api.get(`groups/${id}`);
        group.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function getGroupActivities() {
      try {
        const response = await api.get(`missions?groupId=${id}`);
        activities.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    function getPhotoUrl() {
      const id = route.params.id.toString();
      return `${process.env.VUE_APP_API_BASE_URL}groups/${id}/photo`
    }

    async function getGroupMembers() {
      try {
        const response = await api.get(`groups/${id}/members`);
        members.value = response.data;
      } catch (e) {
        console.log(e);
      }
    }

    function isMember() {
      const groupId = id;
      return store.state.groups.some((id: string) => id === groupId);
    }

    function isOwner() {
      return group.value.owner && store.state.me.id === group.value.owner.id;
    }

    function toggleMember() {
      store.dispatch('toggleMember', id);
    }

    function getMemberPhoto(id: string) {
      return `${process.env.VUE_APP_API_BASE_URL}users/${id}/photos/profile`
    }

    onMounted(async () => {
      await getGroup();
      await getGroupActivities();
      await getGroupMembers();
    });

    return { group, tab, activities, members, getMemberPhoto, getPhotoUrl, isMember, isOwner, toggleMember };
  }
});
