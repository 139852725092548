
import { defineComponent, onMounted, ref } from "vue";
import api from "@/api";
import { useRouter } from "vue-router";
import { readImage } from "@/tools/images";
import { IGroup } from "@/models/IGroup";
import { useStore } from "vuex";

export default defineComponent({
  name: 'ActivityEdit',
  setup() {
    const store = useStore();
    const router = useRouter();
    const group = ref();

    function init() {
      group.value = {
        photo: undefined as string | undefined,
        name: '',
        description: '',
        city: '',
        country: ''
      } as IGroup;
    }

    async function save() {
      try {
        const data = {...group.value};
        const result = await api.post('groups', data);
        await store.dispatch('getGroups');
        await router.push(`/groups/${result.data.id}`);
      } catch(e) {
        console.log(e);
        alert("Error guardando el grupo");
      }
    }

    async function getImage(event: any) {
      try {
        group.value.photo = await readImage(event.target.files[0]);
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () =>
    {
      init();
    });

    return { group, location, save, getImage }
  }
});
